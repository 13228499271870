import React, { Fragment, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Fonts from '../components/Fonts'
import { useDefaultImage } from '../hooks'
import NavContainer from '../components/NavContainer'
import Hero from '../components/Hero'
import Footer from '../components/Footer'

import '../style/all.sass'

const NotFoundPage = ({ data }) => {
  const featuredImage = useDefaultImage()
  const heroRef = useRef()

  return (
    <Fragment>
      <Fonts />
      <Helmet>
        <html lang="en" />
        <title>Page Not Found | Aaron Gervais | Brewer, Composer</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <NavContainer />
      <Hero
        featuredImage={featuredImage}
        ref={heroRef}
        header="404: Page Not Found"
        subheader_MD="Whoops, that page does not exist. Please try something else."
      />
      <Footer />
    </Fragment>
  )
}

export const pageQuery = graphql`
  query NotFoundPageTemplate {
    site {
      ...siteMeta
    }
  }
`

export default NotFoundPage
